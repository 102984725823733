import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = ({ imageUrl }) => {
  const data = useStaticQuery(
    graphql`
      query {
        schemaLogo: file(relativePath: { eq: "icon.png" }) {
          publicURL
        }
      }
    `
  );

  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Amberth",
    url: "https://www.amberth.co.uk",
    logo: `${data.schemaLogo.publicURL}`,
    image: `${imageUrl}`,
    description:
      "Amberth is a design and build company that specializes in bespoke home interiors.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "42-44 Tottenham Lane",
      addressLocality: "London",
      addressRegion: "Greater London",
      postalCode: "N8 7ED",
      addressCountry: "UK",
    },
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+44 20 7354 5169",
      contactType: "Customer Service",
      email: "info@amberth.co.uk",
    },
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "09:00",
        closes: "17:30",
      },
    ],
    sameAs: [
      "https://www.facebook.com/AmberthInteriors",
      "https://www.twitter.com/AmberthInteriors",
      "https://www.instagram.com/AmberthInteriors",
      "https://www.linkedin.com/company/amberth",
    ],
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(localBusinessSchema)}
      </script>
    </Helmet>
  );
};

export default LocalBusinessSchema;
